import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshopNoTab,
  InternalInstructor,
  InternalTestimonial
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import styles from './oraculo-das-flores.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Oráculo das Flores' />
      <InternalHeader withType>
        <small>Online</small>
        <h1>
          Oráculo <br />
          das <strong>Flores</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre o <strong>Workshop</strong>
            </h2>
            <p>
              No Jardim com Dr Bach é uma vivência prática e experiencial com os Florais de Bach e a magia das flores. O
              objetivo é trazer uma ferramenta para complementação da consulta terapêutica.
            </p>
            <p>
              Abordaremos a natureza intuitiva, a ressonância entre o nosso “Eu Interno” e as Flores de Bach.
              Trabalharemos com a imagem das Flores como forma de reação aos sentimentos não conhecidos ou não
              verbalizados pelo cliente.
            </p>
            <h2>
              O que vamos <strong>vivenciar</strong>
            </h2>
            <p>
              Usaremos o oráculo como um guia, uma forma para encontrar o caminho de volta a nós mesmos, ao verdadeiro
              “Eu” - nossa Divindade Interior, um caminho até o mestre que reside dentro de nós.
            </p>
            <p>
              Através das imagens, silenciamos a mente para que se abra um jardim de possibilidades para que as Flores
              de Bach transformem as dores e sofrimento da Alma em um grande despertar de consciência. Um passeio pelo
              inconsciente, uma forma de expressão do “Eu”.
            </p>
            <p>
              De forma simples, mas muito profunda, as imagens das flores tocam nossa Alma, abrem infinitas
              possibilidades de autoconhecimento e empoderamento pessoal.
            </p>
            <p>
              Os cartões podem ser usados por terapeutas como estimulo associativo a outros métodos de resgate da saúde
              e bem-estar emocional, como também por pessoas que desejam elas próprias, conduzirem de forma independente
              seu caminho de busca interior.
            </p>
            <p>
              O baralho pode ser usado de forma individual ou combinado, faremos práticas, dinâmicas e exercícios com
              aplicações variadas.
            </p>
          </InternalContent>
          <div className='aside'>
            <InternalWorkshopNoTab>
              <h3>
                <small>Workshop à Distância</small>
                Oráculo das Flores
              </h3>
              <ul>
                <li>4 horas de workshop</li>
                <li>Materiais incluso (baralho e livro)</li>
                <li>Sem pré-requisitos</li>
                <li>Workshop aberto a todos os públicos </li>
              </ul>
              <a
                href={WHATSAPPLINK}
                target='_blank'
                rel='noopener noreferrer'
                className={`button button--purpleReverse button internalWorkshop-button`}
              >
                Inscrição Workshop Online <small></small>
              </a>
            </InternalWorkshopNoTab>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
